/* You can add global styles to this file, and also import other style files */

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}
@keyframes example {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.page-body {
  padding-left: calc(5vw / 2);
  padding-right: calc(5vw / 2);
}

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

html {
  scroll-behavior: smooth;
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.topnav {
  margin-top: -53px !important;
}

.bottom-nav {
  background: #f5f6fa;
  border-top: 1px solid #edeef0;
  box-shadow: 0px 0rem 0.1rem 0px rgb(18 38 63 / 28%);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}


.bg-light {
  .border-bottom {
    border-bottom: 1px solid #d4d7dc !important;
  }

  .border-left {
    border-bottom: 1px solid #d4d7dc !important;
  }

  .border-right {
    border-bottom: 1px solid #d4d7dc !important;
  }

  .border-top {
    border-bottom: 1px solid #d4d7dc !important;
  }
}

.text-white::placeholder {
  color: white !important;
}

.pointer {
  cursor: pointer;
}

.input-group {
  .ng-select > .ng-select-container {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

body[data-layout="horizontal"][data-layout-size="boxed"] .container-fluid,
body[data-layout="horizontal"][data-layout-size="boxed"] .navbar-header {
  max-width: 100% !important;
}

body .page-content {
  position: relative;
  padding: 125px 0 60px 0 !important;
}

.page-details {
  padding: calc(24px / 2);
}

.page-list {
  position: relative;

  table {
    width: 100%;
    position: relative;
  }

  thead {
    position: sticky !important;
    top: 0;

    tr > th {
      background-color: #9561a9;
      color: white;
    }
  }

  th {
    border: 1px solid #9561a9 !important;
    border-right: 1px solid #9561a9 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 2;
  }

  th[scope="row"] {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0;
    z-index: 1;
    vertical-align: top;
  }

  th:last-child {
    border-right: 1px solid #6a89cc !important;
  }

  tbody tr:hover {
    background-color: #d2dae2 !important;
  }

  tbody {
    td,
    th {
      font-size: 13px;
      padding: 0.5rem 0.8rem;
    }

    td {
      font-size: 13px;
    }
  }

  thead th {
    position: sticky;
    top: 0;
  }

  th,
  td {
    vertical-align: middle !important;
  }

  th.checkbox {
    padding: 0.5rem !important;
    text-align: center;
  }

  td.checkbox {
    text-align: center;
  }

  th.checkbox::before,
  th.no-sort::before {
    content: "" !important;
  }

  th.checkbox::after,
  th.no-sort.checkbox::after,
  th.no-sort::after {
    content: "" !important;
  }
}

.modal-content {
  border: 0px !important;
}

tr.active {
  background-color: #c2c9cf !important;
}

.bg-medium {
  background-color: #dcdde1 !important;
}

.btn-grp {
  button {
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.modal-xl .modal-body {
  height: calc(100vh - 3.5rem);
}

tbody tr:hover {
  background-color: #d2dae2 !important;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: #556ee6 !important;
  border: 1px solid #e6e6e6 !important;
}
