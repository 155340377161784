/*
Template Name: Dyu - Admin & Dashboard Template
Author: Karippal Innovations
Version: 3.0.0
Website: https://karippal.in/
Contact: contact@karippal.in
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";